import React, { forwardRef } from 'react'
import { Anchor, Box, Paragraph } from 'grommet'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'
import {
  ChalkboardTeacher,
  InfoCircle,
  MoneyCheckAlt,
} from '@styled-icons/fa-solid'
import { Handshake } from '@styled-icons/fa-regular'
import { Article, Team } from '@styled-icons/remix-line'
import { LabFlask, PriceRibbon } from '@styled-icons/entypo'
import { FiberNew } from '@styled-icons/material'
import { ContactMail } from '@styled-icons/material-outlined'

const Root = styled.div`
  position: relative;
  background: white;
  z-index: 2;
`
const StyledArticle = styled(Box)`
  padding: 5vh 20vw;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`
const HoneyComb = styled.nav`
  display: grid;
  grid-template-columns: repeat(8, ${p => p.size / 2}px);
  gap: ${p => p.size * 0.32}px ${p => p.size * 0.025}px;
  max-width: ${p => p.size * 5}px;
  margin: 50px auto;
  padding: ${p => p.size * 0.28}px 0;
  position: relative;
  justify-content: center;
  perspective: 600px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(6, ${p => p.size / 2}px);
  }
`
const Cell = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: span 2;
  width: ${p => p.size}px;
  height: ${p => p.size * 0.55}px;
  text-align: center;
  color: white;
  transition: 0.25s ease;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: -50%;
    width: 100%;
    height: 200%;
    background-color: ${p => p.theme.colors.brand};
    color: white;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    transition: 0.4s ease-in;
    box-shadow: inset 0 2px 20px 0 rgba(0, 0, 0, 0.21);
    z-index: -1;
  }
  &:hover::before {
    transform: rotate(180deg);
  }
  &:active {
    transform: rotate3d(0.1, -1, 0.5, 10deg) translateZ(-15px) scale(0.96);
  }
  @media screen and (min-width: 1001px) {
    &:nth-child(7n + 1) {
      grid-column-start: 2;
    }
    &:nth-child(7n + 2) {
      grid-column-start: 4;
    }
    &:nth-child(7n + 3) {
      grid-column-start: 6;
    }
    &:nth-child(7n + 4) {
      grid-column-start: 1;
    }
    &:nth-child(7n + 5) {
      grid-column-start: 3;
    }
    &:nth-child(7n + 6) {
      grid-column-start: 5;
    }
    &:nth-child(7n) {
      grid-column-start: 7;
    }
  }
  @media screen and (max-width: 1000px) {
    &:nth-child(5n + 1) {
      grid-column-start: 1;
    }
    &:nth-child(5n + 2) {
      grid-column-start: 3;
    }
    &:nth-child(5n + 3) {
      grid-column-start: 5;
    }
    &:nth-child(5n + 4) {
      grid-column-start: 2;
    }
    &:nth-child(5n) {
      grid-column-start: 4;
    }
  }
`
const RouteLink = styled(AniLink)`
  color: white;
  width: 100%;
  height: 100%;
`

const About = (props, ref) => {
  const windowWidth = typeof window === 'undefined' ? null : window.innerWidth
  const iconSize = windowWidth && windowWidth < 1000 ? 30 : 46
  const cellSize = windowWidth && windowWidth < 1000 ? 120 : 150

  return (
    <Root>
      <StyledArticle as="article" align="center" ref={ref} id="about">
        <Paragraph size="large" textAlign="center" fill>
          Katedra Nanobiotechnologii powstała w 2019r.
          w{' '}
          <Anchor
            href="http://ib.sggw.pl"
            label="Instytucie Biologii"
            target="_blank"
          />
          , w SGGW w Warszawie. Prace badawcze prowadzone w Katedrze dotyczą
          zastosowania <strong> nanostruktur</strong> (zarówno metali
          szlachetnych, jak i alotropowych form węgla) oraz{' '}
          <strong>nowatorskich substancji bioaktywnych</strong> jako
          nano-biomolekuł o&nbsp;charakterze
          <strong> przeciwnowotworowym, regeneracyjnym i odżywczym</strong>.
          Wykonywane doświadczenia dedykowane są również{' '}
          <strong>ekotoksykologicznym </strong>
          aspektom nanobiotechnologii.
        </Paragraph>
      </StyledArticle>
      <HoneyComb size={cellSize}>
        <Cell size={cellSize}>
          <RouteLink fade to="/news/">
            <FiberNew size={iconSize} />
            <Paragraph margin="small">Aktualności</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/about/">
            <InfoCircle size={iconSize} />
            <Paragraph margin="small">O nas</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/team/">
            <Team size={iconSize} />
            <Paragraph margin="small">Pracownicy</Paragraph>
          </RouteLink>
        </Cell>

        <Cell size={cellSize}>
          <RouteLink fade to="/science/">
            <Article size={iconSize} />
            <Paragraph margin="small">Publikacje</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/science/" state={{ target: 'patents' }}>
            <PriceRibbon size={iconSize} />
            <Paragraph margin="small">Patenty</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/science/" state={{ target: 'grants' }}>
            <MoneyCheckAlt size={iconSize} />
            <Paragraph margin="small">Granty</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/science/" state={{ target: 'coop' }}>
            <Handshake size={iconSize} />
            <Paragraph margin="small">Współprace</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/labs/">
            <LabFlask size={iconSize} />
            <Paragraph margin="small">Laboratoria</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/didactic/">
            <ChalkboardTeacher size={iconSize} />
            <Paragraph margin="small">Dydaktyka</Paragraph>
          </RouteLink>
        </Cell>
        <Cell size={cellSize}>
          <RouteLink fade to="/contact/">
            <ContactMail size={iconSize} />
            <Paragraph margin="small">Kontakt</Paragraph>
          </RouteLink>
        </Cell>
      </HoneyComb>
    </Root>
  )
}

export default forwardRef(About)
