import React from 'react'
import styled from 'styled-components'
import { Heading } from 'grommet'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Particles from 'react-particles-js'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const Root = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`
const Spacing = styled.div`
  height: 55vh;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 1500px) {
    height: 50vh;
  }
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
`
const ParticlesContainer = styled.div`
  position: fixed;
  background: #1d3557;
  height: 55vh;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 1500px) {
    height: 50vh;
  }
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
`
const StyledParticles = styled(Particles)`
  background: #1d3557;
  height: 55vh;
  @media screen and (max-width: 1500px) {
    height: 50vh;
  }
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
`
const TitleContainer = styled.div`
  position: relative;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 2;
  @media screen and (max-width: 1500px) {
    min-height: 50vh;
  }
  @media screen and (max-width: 768px) {
    min-height: 60vh;
  }
`
const Title = styled(Heading)`
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: calc(14px + 1.5vw);
`
const ImgWrap = styled.div`
  cursor: pointer;
`

const windowWidth = typeof window === 'undefined' ? null : window.innerWidth

const particlesConfig = {
  particles: {
    number: {
      value: windowWidth ? Math.round(window.innerWidth / 20) + 20 : 100,
    },
    size: {
      value: 4,
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'repulse',
      },
      onClick: {
        enable: true,
        mode: 'push',
      },
    },
  },
}

const Hero = () => {
  const logoImg = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Root>
      <ParticlesContainer>
        <StyledParticles height="100%" params={particlesConfig} />
      </ParticlesContainer>
      <Spacing />
      <TitleContainer id="about-top">
        <Fade bottom cascade>
          <Title className="fill">Katedra Nanobiotechnologii</Title>
          <Heading level={2} margin={{bottom: 'large'}} style={{ fontSize: 'calc(11px + 1.4vw)' }}>
            Instytut Biologii, SGGW w Warszawie
          </Heading>
          <ImgWrap onClick={() => scrollTo('#about-top')}>
            <Img fixed={logoImg.logo.childImageSharp.fixed} />
          </ImgWrap>
        </Fade>
      </TitleContainer>
    </Root>
  )
}

export default Hero
