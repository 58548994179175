import React from 'react'

import BaseLayout from '../layout/base-layout'
import Hero from '../components/hero'
import About from '../components/about'
import SEO from '../components/seo'
import Header from '../components/header'

const IndexPage = () => (
  <BaseLayout>
    <SEO title="Home" />
    <Header isHome />
    <Hero />
    <About />
  </BaseLayout>
)

export default IndexPage
